
import { defineComponent } from 'vue'
import { getBasicData, BasicDataDto } from '@/api/home'
import { getPayAccount, AccountDto, getPayUrl } from '@/api/invoice'
import user from '@/utils/user'
export default defineComponent({
    name: 'HomeDetail',
    data () {
        return {
            isRechargeFinish: false,
            paymentType: 1,
            paymentNum: 0,
            paymentNumList: [],
            anyAmount: '',
            data: {} as BasicDataDto,
            companyName: '',
            account: {} as AccountDto,
            payForm: ''
        }
    },
    async created () {
        const result = await getBasicData()
        const account = await getPayAccount()
        this.data = result
        this.companyName = user.companyName
        this.account = account
    },
    methods: {
        async onRecharge () {
            // this.isRechargeFinish = true
            const result = await getPayUrl({
                payment: 'alipay',
                money: this.anyAmount
            })
            this.payForm = result.form
        },
        onCopy () {
            var textareaEl = document.createElement('textarea')
            textareaEl.setAttribute('readonly', 'readonly') // 防止手机上弹出软键盘
            textareaEl.value = `开户行：
${this.account.bank_name}
银行卡号：
${this.account.account_number}
开户名称：
${this.account.account_name}`
            document.body.appendChild(textareaEl)
            textareaEl.select()
            document.execCommand('copy')
            document.body.removeChild(textareaEl)
            this.$message.success('复制成功！')
        }
    }
})
